<template>
    <div class="main-container">
        <div class="main-wrapper">
            <div class="search-wrapper">
                <el-input placeholder="请输入您要搜索的商品、类目或链接" v-model="searchInput" class="search-input">
                    <div slot="append" class="search-btn" @click="searchBtn('tb')">搜淘券</div>
                    <div slot="append" class="search-btn" @click="searchBtn('pdd')">搜拼多多</div>
                    <div slot="append" class="search-btn" @click="searchBtn('jd')">搜京东</div>
                </el-input>
                <el-button class="blue-btn" size="small" @click="setPId">设置我的PID</el-button>
            </div>
            <div class="list-wrapper">
                <div class="list-tab">
                    <div class="tab-item" :class="currentTab === 'tb' ? 'current' : ''" @click="toggleTab('tb')">淘好券</div>
                    <div class="tab-item" :class="currentTab === 'pdd' ? 'current' : ''" @click="toggleTab('pdd')">拼多多券</div>
                    <div class="tab-item" :class="currentTab === 'jd' ? 'current' : ''" @click="toggleTab('jd')">京东好券</div>
                </div>
                <el-scrollbar class="list-container"  ref="listContainer">
                    <div class="tab-group">
                        <template v-if="currentTab === 'tb'">
                            <div class="tab-btn" :class="sortCurrent === null ? 'current' : ''" @click="toggleSort(null)">默认</div>
                            <div class="tab-btn" :class="sortCurrent === 4 ? 'current' : ''" @click="toggleSort(4)">最新</div>
                            <div class="tab-btn" :class="sortCurrent === 1 ? 'current' : ''" @click="toggleSort(1)">佣金</div>
                            <div class="tab-btn" :class="sortCurrent === 2 ? 'current' : ''" @click="toggleSort(2)">销量</div>
                            <div class="tab-btn" :class="sortCurrent === 5 ? 'current' : ''" @click="toggleSort(5)">券面额</div>
                            <div class="tab-btn" :class="sortCurrent === -3 ? 'current' : ''" @click="toggleSort(-3)">价格</div>
                        </template>
                        <template v-if="currentTab === 'pdd'">
                            <div class="tab-btn" :class="sortCurrent === null ? 'current' : ''" @click="toggleSort(null)">默认</div>
                            <div class="tab-btn" :class="sortCurrent === 12 ? 'current' : ''" @click="toggleSort(12)">最新</div>
                            <div class="tab-btn" :class="sortCurrent === 3 ? 'current' : ''" @click="toggleSort(3)">券后价</div>
                            <div class="tab-btn" :class="sortCurrent === 5 ? 'current' : ''" @click="toggleSort(5)">券面额</div>
                            <div class="tab-btn" :class="sortCurrent === 6 ? 'current' : ''" @click="toggleSort(6)">销量</div>
                            <div class="tab-btn" :class="sortCurrent === 2 ? 'current' : ''" @click="toggleSort(2)">佣金比例</div>
                        </template>
                        <template v-if="currentTab === 'jd'">
                            <div class="tab-btn" :class="sortCurrent === null ? 'current' : ''" @click="toggleSort(null, null, null)">默认</div>
                            <div class="tab-btn" :class="sortCurrent === sortNameItem.code ? 'current' : ''"
                                 v-for="sortNameItem in sortNameList"
                                 :key="`sortNameList_${sortNameItem.code}`"
                                 @click="toggleSort(sortNameItem.code, sortNameItem.sort, sortNameItem)">
                                {{sortNameItem.name}} <i class="iconfont">{{sortNameItem.sort === 'asc' ? '&#xe8f5;' : '&#xe8f6;'}}</i>
                            </div>
                        </template>
                    </div>
                    <div class="class-group">
                        <div class="group-left">{{currentTab === 'jd' ? '频道' : '分类'}}</div>
                        <div class="group-right">
                            <template v-if="currentTab === 'jd'">
                                <div class="class-item" :class="eliteIdCurrent === channelItem.id ? 'current' : ''"
                                     @click="toggleChannel(channelItem.id)"
                                     v-for="channelItem in channelList" :key="`channelList_${channelItem.id}`">{{channelItem.name}}</div>
                            </template>
                            <template v-else>
                                <div class="class-item" :class="eliteIdCurrent === null ? 'current' : ''" @click="toggleChannel(null)">全部</div>
                                <div class="class-item" :class="classCurrent === classItem.id ? 'current' : ''"
                                     @click="toggleTaoClass(classItem.id)"
                                     v-for="classItem in classList" :key="`taoClassList_${classItem.id}`">{{classItem.name}}</div>
                            </template>
                        </div>
                    </div>
                    <div class="list-content" v-if="goodsList.length > 0">
                        <div class="good-item" v-for="(goodItem, goodIndex) in goodsList" :key="`goodsList_${goodIndex}`">
                            <div class="good-rank" v-if="listPages.currentPageNum === 1">
                                <i class="iconfont">&#xe6b2;</i>
                                <div class="top">TOP</div>
                                <div class="num">{{goodItem.rank}}</div>
                            </div>
                            <div class="good-cover">
                                <a class="cover-wrapper" :href="`http://${goodItem.goodLink}`" target="_blank">
                                    <img :src="goodItem.goodCover" alt="">
                                </a>
                                <div class="good-operate">
<!--                                    v-if="currentTab === 'tb'"-->
                                    <router-link :to="{name: 'studentCollectCoupons', query: {id: goodItem.id}}" class="o-item text-overflow">立即领券</router-link>
<!--                                    <a v-else :href="`https://${goodItem.goodLink}`" target="_blank" class="o-item text-overflow">商品详情</a>-->
                                    <div class="o-item text-overflow" @click="promoteNowBtn(goodItem)">立即推广</div>
                                </div>
                            </div>
                            <div class="good-detail">
                                <a :href="`http://${goodItem.goodLink}`" target="_blank" class="good-name text-overflow-2" :title="goodItem.goodName">{{goodItem.goodName}}</a>
                                <div class="good-sale text-overflow" style="margin-bottom: 0" v-if="currentTab !== 'jd'">月销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <div class="good-sale text-overflow" style="margin-top: 0" v-if="currentTab === 'tb'">2小时销量<span class="orange">{{goodItem.sales}}</span>笔</div>
                                <el-progress class="good-process" :percentage="goodItem.commissionRate" :show-text="false" color="#FF5200"></el-progress>
                                <div class="good-line">
                                    <div class="l-item">
                                        <div class="line1 orange">{{Number(goodItem.price).toFixed(2)}}</div>
                                        <div class="line2">券后</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange">{{goodItem.commissionRate.toFixed(0)}}%</div>
                                        <div class="line2">营销</div>
                                    </div>
                                    <div class="l-item">
                                        <div class="line1 orange" v-if="currentTab === 'jd'">{{goodItem.couponCommission}}</div>
                                        <div class="line1 orange" v-else>{{(goodItem.price * goodItem.commissionRate.toFixed(0) * 0.01).toFixed(2)}}</div>
                                        <div class="line2">佣金</div>
                                    </div>
                                </div>
                                <div class="good-ticket">
                                    <div class="ticket-left">券</div>
                                    <div class="right">{{goodItem.vouchers}}元</div>
                                </div>
                                <div class="store-name text-overflow" v-if="currentTab === 'tb'">{{goodItem.storeName ? goodItem.storeName : '暂无店铺'}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" v-else>
                        <img :src="require('../../../assets/img/image/null-fz.png')" alt="">
                        <div class="text">暂无数据</div>
                    </div>
                </el-scrollbar>
            </div>
            <el-pagination class="circle-page" style="margin: 20px 0"
                           :current-page.sync="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="pagesCurrentChange">
            </el-pagination>
        </div>

        <PIDSet ref="PIDSet" />
    </div>
</template>

<script>
    import axios from 'axios';
    import PIDSet from '@/components/creativecentermodule/PIDSet'
    export default {
        components: {
            PIDSet
        },
        data() {
            return {
                searchInput: '',
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 12,
                    total: 0
                },
                currentTab: 'jd',
                goodsList: [],
                classCurrent: null, // 淘宝、拼多多当前分类
                classList: [], // 淘宝、拼多多分类list
                eliteIdCurrent: 2, // 京东频道当前
                channelList: [
                    {
                        id: 2,
                        name: '精选卖场',
                    },
                    {
                        id: 1,
                        name: '好券商品',
                    },
                    {
                        id: 10,
                        name: '9.9包邮',
                    },
                    {
                        id: 15,
                        name: '京东配送',
                    },
                    {
                        id: 22,
                        name: '实时热销榜',
                    },
                    {
                        id: 23,
                        name: '为你推荐',
                    },
                    {
                        id: 24,
                        name: '数码家电',
                    },
                    {
                        id: 25,
                        name: '超市',
                    },
                    {
                        id: 26,
                        name: '母婴玩具',
                    },
                    {
                        id: 27,
                        name: '家具日用',
                    },
                    {
                        id: 28,
                        name: '美妆穿搭',
                    },
                    {
                        id: 30,
                        name: '图书文具',
                    },
                    {
                        id: 31,
                        name: '今日必推',
                    },
                    {
                        id: 32,
                        name: '京东好物',
                    },
                    {
                        id: 33,
                        name: '京东秒杀',
                    },
                    {
                        id: 34,
                        name: '拼购商品',
                    },
                    {
                        id: 40,
                        name: '高收益榜',
                    },
                    {
                        id: 41,
                        name: '自营热卖榜',
                    },
                    {
                        id: 108,
                        name: '秒杀进行中',
                    },
                    {
                        id: 109,
                        name: '新品首发',
                    },
                    {
                        id: 110,
                        name: '自营',
                    },
                    {
                        id: 112,
                        name: '京东爆品',
                    },
                    {
                        id: 125,
                        name: '首购商品',
                    },
                    {
                        id: 129,
                        name: '高佣榜单',
                    },
                    {
                        id: 130,
                        name: '视频商品',
                    },
                    {
                        id: 153,
                        name: '历史最低价商品榜',
                    },
                    {
                        id: 210,
                        name: '极速版商品',
                    },
                    {
                        id: 238,
                        name: '新人价商品',
                    },
                    {
                        id: 247,
                        name: '京喜9.9',
                    },
                    {
                        id: 249,
                        name: '京喜秒杀',
                    },
                ], // 京东频道list
                sortCurrent: null, // 当前排序
                ascOrDesc: null,// 当前升降序号
                sortNameList: [
                    {
                        code: 'price',
                        name: '单价',
                        sort: 'desc'
                    },
                    {
                        code: 'commissionShare',
                        name: '佣金比例',
                        sort: 'desc'
                    },
                    {
                        code: 'commission',
                        name: '佣金',
                        sort: 'desc'
                    },
                    {
                        code: 'inOrderCount30DaysSku',
                        name: 'sku维度30天引单量',
                        sort: 'desc'
                    },
                    {
                        code: 'comments',
                        name: '评论数',
                        sort: 'desc'
                    },
                    {
                        code: 'goodComments',
                        name: '好评数',
                        sort: 'desc'
                    },
                ] // 京东排序
            }
        },
        mounted() {
            this.getClassList()
            this.getGoodsList()
            this.getUrl()
        },
        methods: {
            getUrl() {
                // let url = 'https://qr.m.jd.com/check?appid=133&token=akagr2hps8zblo8v73tco358z3lz4bsf&_=1615169737545&callback=__jsonp1615169169491&_=1615169737545'
                // let arr1 = url.split('token=')
                // let arr2 = arr1[1].split('&')
                // let timestamp = Date.parse(new Date());
                // let url1Tmp = `https://qr.m.jd.com/show?appid=133&size=147&t=${timestamp}`
                // let url2Tmp = `https://qr.m.jd.com/check?appid=133&token=${arr2[0]}&_=${timestamp}&callback=__jsonp${timestamp}&_=${timestamp}`
                // console.log('url1Tmp', url1Tmp)
                // // console.log('url2Tmp', url2Tmp)
                //
                // this.$httpStudent.axiosGet(`${url1Tmp}`, (res) => {
                //     console.log('999res', res)
                // }, (err) => {
                //     console.log('err', err)
                // })

                // axios.get(`${url1Tmp}`).then((response) => {
                //     console.log('response', response)
                // }).catch((err) => {
                //     console.log('err', err)
                // })
            },
            getClassList() {
                let params = {
                    mode: this.currentTab
                }
                this.$httpStudent.axiosGetBy(this.$api.promotionClass, params, (res) => {
                    if (res.code === 200) {
                        this.classList = res.data
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            getGoodsList() {
                let params = {
                    mode: this.currentTab,
                }
                if (this.currentTab === 'jd') {
                    params.eliteId = this.eliteIdCurrent
                    params.sortName = this.sortCurrent
                    params.sort = this.ascOrDesc
                    params.search = this.searchInput
                    params.pageIndex = this.listPages.currentPageNum
                    params.pageSize = this.listPages.eachPageNum
                }
                console.log('params', params)
                this.$httpStudent.axiosGetBy(this.$api.promotionList, params, (res) => {
                    // console.log('jdList', res)
                    if (res.code === 200) {
                        if (res.data.constructor === Object) {
                            this.goodsList = res.data.data
                            this.listPages.total = Number(res.data.total)
                            this.$refs['listContainer'].wrap.scrollTop = 0
                        } else {
                            this.goodsList = res.data
                        }
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 搜索商品
            searchBtn(type) {
                this.currentTab = type
                this.listPages.currentPageNum = 1
                this.listPages.total = 0
                this.getClassList()
                this.getGoodsList()
            },
            // 切换平台tab
            toggleTab(val) {
                this.currentTab = val
                this.classCurrent = null
                this.sortCurrent = null
                this.eliteIdCurrent = 2
                this.listPages.currentPageNum = 1
                this.listPages.total = 0
                this.getClassList()
                this.getGoodsList()
            },
            // 切换分类
            toggleTaoClass(id) {
                this.classCurrent = id
                this.listPages.currentPageNum = 1
                this.listPages.total = 0
                this.getGoodsList()
            },
            // 切换频道
            toggleChannel(id) {
                this.eliteIdCurrent = id
                this.listPages.currentPageNum = 1
                this.listPages.total = 0
                this.getGoodsList()
            },
            // 筛选sort
            toggleSort(val, sort, item) {
                this.sortCurrent = val
                if (val && sort) { // 京东的
                    if (sort === 'desc') {
                        this.$set(item, 'sort', 'asc')
                    } else {
                        this.$set(item, 'sort', 'desc')
                    }
                    this.ascOrDesc = sort
                }
                this.listPages.currentPageNum = 1
                this.listPages.total = 0
                this.getGoodsList()
            },
            // 淘宝 立即推广
            promoteNowBtn(val) {
                console.log('val', val)
            },
            // 设置我的pid
            setPId() {
                this.$refs.PIDSet.dialogPID = true
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-container {
        position: relative;
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        background: #fff;
        border-radius: 10px 10px 0 0;
        .main-wrapper {
            height: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .search-wrapper {
                position: relative;
                .search-input {
                    margin: 45px auto 0;
                    display: flex;
                    align-items: center;
                    width: 730px;
                    ::v-deep .el-input__inner {
                        width: 450px;
                        color: #222;
                        border-color: #FF7B4B;
                    }
                    ::v-deep .el-input-group__append, ::v-deep .el-input-group__prepend {
                        padding: 0;
                        border: 0;
                        overflow: hidden;
                        display: flex;
                        width: auto;
                        .search-btn {
                            background-color: #FE4800;
                            border-color: #FE4800;
                            color: #fff;
                            font-size: 16px;
                            cursor: pointer;
                            padding: 12px 20px;
                            line-height: 16px;
                        }
                    }
                }
                .blue-btn {
                    position: absolute;
                    top: 49px;
                    right: 50px;
                }
            }
            .list-wrapper {
                height: 1%;
                flex: 1;
                margin-top: 55px;
                display: flex;
                flex-direction: column;
                .list-tab {
                    background: #EFF0FF;
                    padding: 0 100px;
                    display: flex;
                    justify-content: space-between;
                    .tab-item {
                        font-size: 16px;
                        line-height: 50px;
                        cursor: pointer;
                        position: relative;
                        transition: all .3s;
                        &.current {
                            font-size: 18px;
                            font-weight: 500;
                            &:before {
                                content: '';
                                position: absolute;
                                left: 50%;
                                bottom: 2px;
                                width: 0;
                                height: 0;
                                transform: translateX(-50%);
                                border-left: 4px solid transparent;
                                border-right: 4px solid transparent;
                                border-bottom: 4px solid #9ACC5B;
                            }
                            &:after {
                                content: '';
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                height: 2px;
                                background: #9ACC5B;
                            }
                        }
                    }
                }
                .list-container {
                    flex: 1;
                    height: 1%;
                    ::v-deep > .el-scrollbar__wrap {
                        overflow-x: hidden;
                    }
                    .list-content {
                        padding: 10px 100px 16px 50px;
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .good-item {
                        width: calc(16.666666% - 50px);
                        margin: 30px 0 0 50px;
                        position: relative;
                        .good-rank {
                            position: absolute;
                            top: -7px;
                            left: 10px;
                            z-index: 1;
                            color: #FAE46A;
                            display: none;
                            .iconfont {
                                font-size: 58px;
                                color: #EDB151;
                            }
                            .top {
                                position: absolute;
                                top: 6px;
                                width: 62px;
                                text-align: center;
                                font-size: 16px;
                                font-weight: 700;
                            }
                            .num {
                                position: absolute;
                                top: 26px;
                                width: 62px;
                                text-align: center;
                                font-size: 24px;
                                font-weight: 600;
                            }
                        }
                        &:nth-of-type(1) {
                            .good-rank {
                                display: block;
                            }
                        }
                        &:nth-of-type(2) {
                            .good-rank {
                                display: block;
                                color: #E6EEF5;
                                .iconfont {
                                    color: #B2C7D8;
                                }
                            }
                        }
                        &:nth-of-type(3) {
                            .good-rank {
                                display: block;
                                color: #F7D6A6;
                                .iconfont {
                                    color: #D3AD6F;
                                }
                            }
                        }
                        .good-cover {
                            width: 100%;
                            height: 0;
                            padding-bottom: 100%;
                            position: relative;
                            overflow: hidden;
                            background: #EFF0FF;
                            .cover-wrapper {
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                //top: 50%;
                               // left: 50%;
                                //transform: translate(-50%, -50%);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .good-operate {
                                position: absolute;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                display: flex;
                                .o-item {
                                    width: 50%;
                                    color: #fff;
                                    text-align: center;
                                    line-height: 28px;
                                    background: rgba(255, 82, 0, .8);
                                    position: relative;
                                    cursor: pointer;
                                    font-size: 13px;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        top: 9px;
                                        right: 0;
                                        width: 1px;
                                        height: 10px;
                                        background: #fff;
                                    }
                                    &:last-child {
                                        &:after {
                                            content: none;
                                        }
                                    }
                                    &.gray {
                                        color: #333;
                                        background: rgba(221, 221, 221, .9);
                                    }
                                }
                            }
                        }
                        .good-detail {
                            padding: 12px 7px;
                            box-shadow: 0 0 16px 1px rgba(83, 73, 218, 0.2);
                        }
                        .good-name {
                            color: #222;
                            transition: all .3s;
                            height: 40px;
                            &:hover {
                                color: #FE4800;
                            }
                        }
                        .good-sale {
                            margin: 25px 0;
                            color: #666;
                            text-align: center;
                        }
                        .good-process {
                            margin-top: 25px;
                            ::v-deep .el-progress-bar__outer {
                                background-color: #FFD3C2;
                            }
                        }
                        .good-line {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                            .l-item {
                                text-align: center;
                                .line1, .line2 {
                                    line-height: 1;
                                }
                                .line2 {
                                    color: #666;
                                    font-size: 12px;
                                    margin-top: 6px;
                                }
                            }
                        }
                        .good-ticket {
                            margin-top: 8px;
                            display: flex;
                            align-items: center;
                            .ticket-left {
                                width: 22px;
                                background: #EC6941;
                                font-size: 12px;
                                color: #fff;
                                text-align: center;
                                line-height: 20px;
                                border-radius: 2px 0 0 2px;
                            }
                            .right {
                                font-size: 12px;
                                color: #FE4800;
                                border: 1px solid #EC6941;
                                height: 20px;
                                padding: 0 6px;
                                box-sizing: border-box;
                                border-radius: 0 2px 2px 0;
                            }
                        }
                        .store-name {
                            font-size: 12px;
                            margin-top: 12px;
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    .orange {
        color: #FE4800;
    }
    .tab-group {
        display: flex;
        flex-wrap: wrap;
        padding: 0 100px 0 80px;
        .tab-btn {
            font-size: 16px;
            line-height: 30px;
            padding: 0 20px;
            background: #EFF0FF;
            border-radius: 4px;
            margin-left: 20px;
            margin-top: 20px;
            cursor: pointer;
            transition: all .3s;
            &:hover, &.current {
                color: #fff;
                background: #FE4800;
            }
        }
    }
    .class-group {
        display: flex;
        padding: 0 100px;
        margin-top: 10px;
        font-size: 16px;
        line-height: 1;
        .group-left {
            font-weight: 500;
            margin-top: 20px;
        }
        .group-right {
            width: 1%;
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            margin-left: 20px;
            .class-item {
                cursor: pointer;
                margin-left: 20px;
                margin-top: 20px;
                transition: all .3s;
                &:hover, &.current {
                    color: #FE4800;
                }
            }
        }
    }
    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        margin-top: 100px;
        img {
            height: 100px;
        }
        .text {
            color: #787D9B;
            line-height: 1;
            margin-top: 50px;
        }
    }
</style>